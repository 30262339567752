import React from "react";
import { Waiting } from "../components/Waiting";

export const JoinUs = () => {
  return (
    <div>
      <Waiting name="recrutement" />
    </div>
  );
};
