import React from "react";

const Facebook = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 500 500"
        version="1.1"
      >
        <path
          d="M 275.902 1.955 C 244.980 8.478, 217.550 29.814, 203.205 58.500 C 198.290 68.329, 194.187 81.153, 192.975 90.480 C 192.473 94.341, 192.048 120.787, 192.031 149.250 L 192 201 158.723 201 C 122.290 201, 122.630 200.948, 119.494 207.011 C 117.261 211.330, 117.281 276.708, 119.517 281.033 C 122.510 286.820, 123.714 287, 159.450 287 L 192 287 192 390.847 L 192 494.694 201.750 496.357 C 207.113 497.272, 215.550 498.462, 220.500 499.002 C 230.289 500.070, 271.105 500.347, 274.750 499.370 L 277 498.767 277 392.883 L 277 287 319.532 287 C 367.047 287, 366.179 287.114, 372.187 280.095 C 375.444 276.289, 375.856 275.091, 376.955 266.215 C 377.616 260.872, 378.815 251.550, 379.619 245.500 C 380.423 239.450, 381.337 229.550, 381.651 223.500 C 382.192 213.061, 382.099 212.322, 379.831 209.015 C 378.516 207.099, 375.654 204.519, 373.470 203.282 C 369.513 201.040, 369.350 201.033, 323.173 201.016 L 276.846 201 277.185 159.250 L 277.523 117.500 280.693 110.762 C 287.040 97.273, 299.299 88.030, 313.500 86.027 C 317.350 85.484, 333.022 85.031, 348.327 85.020 L 376.154 85 379.077 82.077 L 382 79.154 382 42.500 L 382 5.846 379.077 2.923 L 376.154 0 330.327 0.070 C 290.072 0.132, 283.455 0.361, 275.902 1.955"
          stroke="none"
          fill="#ffffff"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default Facebook;
